<template>  
    <ag-grid-vue id="active-project-list" class="ag-grid ag-theme-balham"
          :gridOptions="gridOptions"
          @grid-ready="onGridReady"
          :columnDefs="columnDefs"
          :context="context"
          :defaultColDef="defaultColDef"
          pagination
          :paginationPageSize="1000"
          :rowData="rowData"
          rowModelType="serverSide"
          :overlayNoRowsTemplate="overlayNoRowsTemplate"
          :sideBar="false"
          suppressDragLeaveHidesColumns
          suppressCellFocus
          suppressScrollOnNewData
          suppressContextMenu
          suppressMultiSort
          >
    </ag-grid-vue>
</template>

<script>
import { projectService } from '@/services';
import 'ag-grid-enterprise';
import { AgGridVue } from 'ag-grid-vue';
import DateTimeCellRenderer from '@/components/Aggrid/CellRenderer/DateTime';
import PercentageCellRenderer from '@/components/Aggrid/CellRenderer/Percentage';
import LinkCellRenderer from '@/components/Aggrid/CellRenderer/Link';

function ServerSideDatasource(self) {
  return {
    getRows(params) {
      const p = self.buildParams(params);
      if (self.canView('PROJECT', ['scheduleStart', 'scheduleFinish'])) {
        projectService.listActive(p).then((response) => {
          params.successCallback(response.data, response.data.length);
          if (response.data.length == 0) {
            self.gridApi.showNoRowsOverlay();
          } else {
            self.gridApi.hideOverlay();
            self.gridColumnApi.autoSizeColumn('name', false);
          }
        })
        .catch(function(error) {
          console.error(error);// eslint-disable-line no-console
          params.successCallback([], self.totalRecords);
          self.gridApi.showNoRowsOverlay();
        });
      }
      else {
        params.successCallback([], self.totalRecords);
        self.gridApi.showNoRowsOverlay();
      }
    }
  }
}

export default {
  name: 'ActiveTaskList',
  components: {
    'ag-grid-vue': AgGridVue,
    //aggrid cell renderer/editor/header component
    /* eslint-disable vue/no-unused-components */
    linkCellRenderer: LinkCellRenderer,
    dateTimeCellRenderer: DateTimeCellRenderer,
    percentageCellRenderer: PercentageCellRenderer
    /* eslint-enable vue/no-unused-components */
  },
  data: function() {
    return {
      gridOptions: null,
      gridApi: null,
      columnApi: null,
      columnDefs: null,
      context: null,
      defaultColDef: null,
      rowData: null,
    };
  },
  props: {
    refresh:    { type: Boolean, default: false }
  },
  watch: {
    refresh(newValue) {
      if (newValue) {
        this.gridApi.refreshServerSide({ purge: true });
        this.$emit('refreshed');
      }
    }
  },
  beforeMount() {
    const self = this;
    this.gridOptions = {
      onGridSizeChanged: function(event) {
        //self.gridColumnApi.autoSizeColumn('name', false);
      }
    };
    this.columnDefs = [
      {
        headerName: this.$t('active_project.grid.name'),
        field: 'name',
        flex: 2,
        cellRenderer: 'linkCellRenderer',
        cellRendererParams: {
          value: function() {
            return this.data.name;
          },
          clicked: function(field, e) {
            self.projectId = this.data.uuId;
            self.renderPopup(e);
          },
        },
        comparator: (valueA, valueB, nodeA, nodeB) => {
          if(nodeA.group == nodeB.group) {
            if (valueA === null && valueB === null) {
              return 0;
            }
            if (valueA === null) {
              return -1;
            }
            if (valueB === null) {
              return 1;
            }
            return nodeA.data.name.toLowerCase().localeCompare(nodeB.data.name.toLowerCase());
          } else if(nodeA.group) {
            return 1;
          } else if(nodeB.group) {
            return -1;
          }
        }
      },
      {
        headerName: this.$t('active_project.grid.start'),
        field: 'startTime',
        sort: 'asc',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_project.grid.status'),
        field: 'status',
        flex: 1
      },
      {
        headerName: this.$t('active_project.grid.end'),
        field: 'endTime',
        flex: 1,
        cellRenderer: 'dateTimeCellRenderer'
      },
      {
        headerName: this.$t('active_project.grid.progress'),
        field: 'actualProgress',
        minWidth: 80,
        maxWidth: 100,
        flex:1,
        cellRenderer: 'percentageCellRenderer'
      },
    ];
    this.defaultColDef = {
      resizable: true,
      sortable: true,
      minWidth: 100,
      menuTabs: []
    };
    this.context = {
      componentParent: self
    };
  },
  computed: {
    overlayNoRowsTemplate() {
      return `<span class='grid-overlay'>No active projects</span>`;
    },
    companyrule() {
      if (this.$store.state.company && this.$store.state.company.type !== 'Primary' &&
          this.$store.state.company.filterIds) {
        const companies = this.$store.state.company.filterIds;
        const companyrule = ['PROJECT.COMPANY.uuId', 'within', companies.join('|')];
        return companyrule
      }
      return null;
    },
  },
  methods: {
    onGridReady(params) {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      const self = this;
      const updateData = () => {
        params.api.setServerSideDatasource(new ServerSideDatasource(self));
      };
      updateData();
    },
    buildParams({ request: {sortModel, endRow, startRow} }) {
      const params = {
        start: startRow,
        limit: endRow - startRow + 1,
      };
      params.ksort = []
      params.order = []
      for(let i = 0, len = sortModel.length; i < len; i++) {
        params.ksort.push(sortModel[i].colId);
        params.order.push(sortModel[i].sort === 'asc'? 'incr' : 'decr');
      }
      params.companyrule = this.companyrule;
      return params;
    },
    getParentElement(elem) {
      if (elem.classList.contains('grid-stack-item')) {
        return elem;
      }
      return this.getParentElement(elem.parentElement);
    },
    renderPopup(e) {
      this.popupRemoveMenu();
      const target = e.target;
      const isMinimized = document.body.classList.contains('sidebar-minimized');     
      
      const container = document.getElementById('active-project-list');
      const rect = target.getBoundingClientRect();
      const parentContainer = this.getParentElement(target);
      const left = (e.x - (isMinimized ? 180 : 300)) - parentContainer.offsetLeft;
      let top = (e.y + (window.scrollY - parentContainer.offsetTop)) - 200;
      const containerHeight = container.offsetTop + container.offsetHeight;
      const menuItems = `<div class="staffpopupmenuoption ag-menu-option" tabindex="-1" role="treeitem" aria-level="1" data-action="goto-day">
                      <span ref="eIcon" class="ag-menu-option-icon" role="presentation"></span>
                      <span ref="eName" class="staffpopupmenutext">${this.$t('staff.overs_timescale_msg')}</span>
                  </div>`;
      
      const staffUsage = this.canView('TASK') && this.canView('STAFF') ? `
        <div class="ag-menu-option cursor-pointer" tabindex="-1" role="treeitem" aria-level="1" data-action="staff_usage">
            <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text popup-menu-font-size">${ this.$t('project.menu.staff_usage') }</span>
            <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
            <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>` : '';
      const dashboard = this.canView('TASK') ? `
        <div class="ag-menu-option cursor-pointer" tabindex="-1" role="treeitem" aria-level="1" data-action="dashboard">
            <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text popup-menu-font-size">${ this.$t('project.menu.dashboard') }</span>
            <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
            <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>` : '';
      const tasks = this.canView('TASK') ? `
        <div class="ag-menu-option cursor-pointer" tabindex="-1" role="treeitem" aria-level="1" data-action="tasks">
            <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text popup-menu-font-size">${ this.$t('project.menu.tasks') }</span>
            <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
            <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>` : '';
      const gantt = this.canView('TASK') ? `
        <div class="ag-menu-option cursor-pointer" tabindex="-1" role="treeitem" aria-level="1" data-action="gantt">
            <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text popup-menu-font-size">${ this.$t('project.menu.gantt') }</span>
            <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
            <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>` : '';
      const kanban = this.canView('TASK') ? `
        <div class="ag-menu-option cursor-pointer" tabindex="-1" role="treeitem" aria-level="1" data-action="board">
            <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text popup-menu-font-size">${ this.$t('project.menu.board') }</span>
            <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
            <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>` : '';
      const resourceUsage = this.canView('TASK') && this.canView('RESOURCE') ? `
        <div class="ag-menu-option cursor-pointer" tabindex="-1" role="treeitem" aria-level="1" data-action="resource_usage">
            <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
            <span ref="eName" class="ag-menu-option-part ag-menu-option-text popup-menu-font-size">${ this.$t('project.menu.resource_usage') }</span>
            <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
            <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>` : '';
      const divider = this.canView('TASK') ? `
        <div class="ag-menu-option cursor-pointer" tabindex="-1" role="treeitem" aria-level="1" data-action="none">
            <span ref="eIcon" class="separator-height separator-color ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
            <span ref="eName" class="separator-height separator-color ag-menu-option-part ag-menu-option-text"></span>
            <span ref="eShortcut" class="separator-height separator-color ag-menu-option-part ag-menu-option-shortcut"></span>
            <span ref="ePopupPointer" class="separator-height separator-color ag-menu-option-part ag-menu-option-popup-pointer"></span>
        </div>` : '';
                  
      const htmlContentStr = `
        <div class="projectal-context-menu ag-tabs ag-menu ag-focus-managed ag-ltr ag-popup-child" role="dialog" aria-label="Column Menu">
          
          <div role="presentation" class="ag-tabs-body ag-menu-body">
              <div class="padding-zero ag-menu-list ag-focus-managed" role="tree">
                  <div class="ag-tab-guard ag-tab-guard-top" role="presentation" tabindex="0"></div>
                  <div class="ag-menu-option cursor-pointer" tabindex="-1" role="treeitem" aria-level="1" data-action="edit-project">
                      <span ref="eIcon" class="ag-menu-option-part ag-menu-option-icon" role="presentation"></span>
                      <span ref="eName" class="ag-menu-option-part ag-menu-option-text popup-menu-font-size">${ this.$t('project.title_detail') }</span>
                      <span ref="eShortcut" class="ag-menu-option-part ag-menu-option-shortcut"></span>
                      <span ref="ePopupPointer" class="ag-menu-option-part ag-menu-option-popup-pointer"></span>
                  </div>
                  ${divider}
                  ${dashboard}
                  ${tasks}
                  ${gantt}
                  ${staffUsage}
                  ${kanban}
                  <div class="ag-tab-guard ag-tab-guard-bottom" role="presentation" tabindex="0"></div>
              </div>
          </div>
      </div>
      `;
      if (this.canView('TASK') &&
          top + 220 > containerHeight) {
        top -= 220;
      }
      const cellMenu = this.htmlToElement(htmlContentStr.trim().replaceAll(/>\n[\s]+/g, '>'));
      cellMenu.style.left = `${left}px`;
      cellMenu.style.top = `${top}px`;
      let popupElement = null;
      popupElement = document.createElement('div');
      popupElement.classList.add('ag-theme-balham')
      popupElement.classList.add('ag-popup');
      popupElement.classList.add('header-group-column-popup');
      popupElement.appendChild(cellMenu);
      document.querySelector('#active-project-list').appendChild(popupElement);

      //Register events for menu options      
      const menuOptions = cellMenu.querySelectorAll('.ag-menu-option');
      if (menuOptions != null) {
        for (let i = 0, len = menuOptions.length; i < len; i++) {
          //'Click'
          menuOptions[i].addEventListener('click', this.handleMenuOptionClick);
          //'Mouseenter' and 'Mouseleave'
          menuOptions[i].addEventListener('mouseenter', this.addMenuOptionHighlight);
          menuOptions[i].addEventListener('mouseleave', this.removeMenuOptionHighlight);
        }
      }

      //Register event for hiding menu when clicking outside of menu
      this.clickOutsideEventHandler = function(e) {
        const popupElem = document.querySelector('.header-group-column-popup');
        if (popupElem != null) {
          if (!popupElem.contains(e.target)) {
            popupElem.remove();
          }
        }
      }
      document.addEventListener('mousedown', this.clickOutsideEventHandler);
      
      this.popupElement = popupElement;
      popupElement.addEventListener('mouseleave', this.popupRemoveMenu);
    },
    popupRemoveMenu() {
        
      if (this.popupElement) {
        this.popupElement.removeEventListener('mouseleave', this.popupRemoveMenu);
        this.popupElement.remove();
        delete this.popupElement;
      }
      
    },
    handleMenuOptionClick(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const action = menuOption.dataset.action;
      if (action == 'edit-project') {
        this.$emit('editProject', this.projectId);
      } else if (action == 'dashboard') {
        this.$router.push(`/projects/summary/${this.projectId}`);
      } else if (action == 'tasks') {
        this.$router.push(`/projects/tasks/${this.projectId}`);
      } else if (action == 'gantt') {
        this.$router.push(`/projects/gantt/${this.projectId}`);
      } else if (action == 'staff_usage') {
        this.$router.push(`/projects/staff/${this.projectId}`);
      } else if (action == 'resource_usage') {
        this.$router.push(`/projects/resource/${this.projectId}`);
      } else if (action == 'board') {
        this.$router.push(`/projects/kanban/${this.projectId}`);
      }
      this.cleanUp();
    },
    addMenuOptionHighlight(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const activeClass = 'ag-menu-option-active';
      if (!menuOption.classList.contains(activeClass)) {
        menuOption.classList.add(activeClass);
      }
    },
    removeMenuOptionHighlight(event) {
      const menuOption = event.target.closest('.ag-menu-option');
      const activeClass = 'ag-menu-option-active';
      if (menuOption.classList.contains(activeClass)) {
        menuOption.classList.remove(activeClass);
      }
    },
    htmlToElement(html) {
      var template = document.createElement('template');
      html = html.trim(); // Never return a text node of whitespace as the result
      template.innerHTML = html;
      return template.content.firstChild;
    },
    handleMenuHeaderClick(/* event */) {
      this.cleanUp();
    },
    cleanUp() {
      if (this.clickOutsideEventHandler != null) {
        this.clickOutsideEventHandler({ target: null });
        document.removeEventListener('mousedown', this.clickOutsideEventHandler);
        this.clickOutsideEventHandler = null;
      }
    },
  }
}
</script>
