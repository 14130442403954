<template>
  <div :id="elemId" style="height: 100%, width: 100%">
    <b-modal v-model="selectorShow" size="xl" :title="selectorTitleLabel" footer-class="footerClass"
      no-close-on-backdrop
      :no-close-on-esc="isCellEditing || editorShow || historyShow" :modal-class="[elemId]"
      content-class="shadow" @hidden="hidden">

      <template #modal-header="{ cancel }">
        <h5 class="custom-modal-title">
          {{ selectorTitleLabel }}
        </h5>
        <template v-if="activeTab != -1">
          <b-button class="history-button" variant="secondary" size="sm" @click="historyShow = true">
            <font-awesome-icon :icon="['far', 'clock-rotate-left']"/>
            {{ $t('button.history') }}
          </b-button>
        </template>
        <button class="close custom-modal-close" @click="cancel()">×</button>
      </template>

      <template v-if="isMissingData">
        <div class="modal-message-overlay">
        <span class="grid-overlay">{{ $t('entity_selector.error.fail_to_show_data') }}</span>
        </div>
      </template>
      <template v-else>

        <AlertFeedback v-if="alertObj.msg != null" 
          :msg="alertObj.msg" 
          :details="alertObj.msgDetails.list" 
          :detailTitle="alertObj.msgDetails.title" 
          :alertState="alertObj.state" 
          @resetAlert="resetAlert"
        />

        <div class="selector-navbar" v-if="enumList != null && Object.keys(enumList).length > 0">
          <PriorityNavigation class="selector-nav" ref="selector-nav" ulClassName="nav nav-pills">
            <li v-for="(value, name, index) in enumList" :key="index" :name="name" 
                class="nav-pills nav-link" :class="{ active: index == 0 }" 
                @click.stop="selectorNavClick">
              <a href="#" target="_self">{{ getTabLabel(name) }}</a>
            </li>
          </PriorityNavigation>
        </div>

        <div class="mt-3">
          <PriorityNavigation class="grid-toolbar border">
            <li v-if="activeTabValue.addingEnabled" :id="`BTN_ADD_${elemId}`">
              <b-btn :disabled="disableAdd" @click="openEditor(true, activeTabName)"><font-awesome-icon :icon="['far', 'plus']" :style="{ color: 'var(--grid-toolbar-button)' }"/></b-btn>
              <b-popover :target="`BTN_ADD_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.add') }}
              </b-popover>
            </li>
            
            <li v-if="activeTabValue.renamingEnabled" :id="`BTN_EDIT_${elemId}`">
              <b-btn :disabled="disableEdit" @click="openEditor(false, activeTabName)"><font-awesome-icon :icon="['far', 'pen-to-square']"/></b-btn>
              <b-popover :target="`BTN_EDIT_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.edit') }}
              </b-popover>
            </li>
            <li  v-if="activeTabValue.removingEnabled" :id="`BTN_DELETE_${elemId}`">
              <b-btn :disabled="disableDelete" @click="rowDelete(activeTabName)"><font-awesome-icon :icon="['far', 'trash-can']"/></b-btn>
              <b-popover :target="`BTN_DELETE_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.delete') }}
              </b-popover>
            </li>
            <li :id="`BTN_MOVEDOWN_${elemId}`">
              <b-btn :disabled="disableReorder" @click="moveRow(activeTabName, false)"><font-awesome-icon :icon="['far', 'arrow-down']"/></b-btn>
              <b-popover :target="`BTN_MOVEDOWN_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.move_down') }}
              </b-popover>
            </li>
            <li :id="`BTN_MOVEUP_${elemId}`">
              <b-btn :disabled="disableReorder" @click="moveRow(activeTabName, true)"><font-awesome-icon :icon="['far', 'arrow-up']"/></b-btn>
              <b-popover :target="`BTN_MOVEUP_${elemId}`" triggers="hover" placement="top" boundary="viewport">
                {{ $t('button.move_up') }}
              </b-popover>
            </li>
          </PriorityNavigation>
          <ag-grid-vue style="width: 100%;" class="ag-theme-balham selector-grid-height-with-tabs" id="enum-grid"
                :gridOptions="gridOptions"
                @grid-ready="onGridReady"
                :columnDefs="columnDefs"
                :context="context"
                :defaultColDef="defaultColDef"
                :getRowId="params => params.data.num"
                pagination
                :paginationPageSize="1000"
                :paginationPageSizeSelector="false"
                :rowData="rowData"
                :rowMultiSelectWithClick="false"
                rowSelection="multiple"
                :serverSideInfiniteScroll="true"
                :sideBar="false"
                suppressContextMenu
                suppressDragLeaveHidesColumns
                :suppressCellFocus="false"
                :singleClickEdit="false"
                enableRangeSelection
                suppressMultiSort
                suppressRowClickSelection
                
                
                :navigateToNextCell="navigateToNextCell"
                :tabToNextCell="tabToNextCell"
                @cell-key-down="onCellKeyDown"
                
                @cell-focused="cellFocused"

                noRowsOverlayComponent="noRowsOverlay"
                :noRowsOverlayComponentParams="noRowsOverlayComponentParams"
                :overlayLoadingTemplate="overlayLoadingTemplate"
                enableCellEditingOnBackspace

                @cell-editing-started="onCellEditingStarted"
                @cell-editing-stopped="onCellEditingStopped"

                :processCellForClipboard="processCellForClipboard"
            
                @paste-start="onPasteStart"
                @paste-end="onPasteEnd" 
                >
          </ag-grid-vue>
          <!-- :cacheBlockSize="10000" -->
        </div>
      </template>

      <template v-slot:modal-footer="{ cancel }">
        <b-button size="sm" variant="danger" @click="cancel()">{{ $i18n.t('button.close') }}</b-button>
      </template>
    </b-modal>

    <EnumModal v-if="editorShow" 
      :selectedCode="editorCode" 
      :selectedNum="editorNum"
      :enumName="activeTabName"
      :enumObj="activeTabValue"
      :show.sync="editorShow" 
      @ok="editorOk"
    />

    <EnumCurrencySelectorModal v-if="enumList != null && enumList.CurrencyEnum != null && Array.isArray(enumList.CurrencyEnum.list)" :show.sync="currencySelectorShow" :preselected="enumList.CurrencyEnum.list" @ok="currencySelectorOk" />
    
    <InProgressModal :show.sync="inProgressShow" :label="inProgressLabel" :isStopable="inProgressStoppable"/>

    <b-modal :title="$t('entity_selector.confirm_deletion_title')"
        v-model="ecConfirmDeleteShow"
        @hidden="ecConfirmDeleteShow=false"
        @ok="ecConfirmDeleteOk"
        @cancel="ecConfirmDeleteCancel"
        content-class="entity-delete-modal shadow"
        no-close-on-backdrop
        >
      
      <p>{{ ecConfirmDeleteStatement }}</p>
      
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-form-checkbox v-if="ecShowApplyAllCheckbox" class="apply-to-all" v-model="entityCol.applyAll">{{ $t('apply_to_all') }}</b-form-checkbox>
        <b-button size="sm" variant="success" @click="ok()">{{ $t('button.confirm') }}</b-button>
        <b-button size="sm" variant="danger" @click="cancel()">{{ $t('button.cancel') }}</b-button>
      </template>
    </b-modal>

    <template v-if="activeTab != -1">
      <EnumHistoryModal v-if="historyShow" :show.sync="historyShow" :enumType="activeTabName" />
    </template>
  </div>
</template>


<script>
import 'ag-grid-enterprise'
import { AgGridVue } from 'ag-grid-vue'
import { objectClone } from '@/helpers'
import alertStateEnum from '@/enums/alert-state'
import RowSelectorCellRenderer from '@/components/Aggrid/CellRenderer/SelectorRowSelector'
import SelectorHeaderComponent from '@/components/Aggrid/CellHeader/SelectorRangeSelection'
import NameEditor from '@/components/Aggrid/CellEditor/Name'
import DetailLinkCellRenderer from '@/components/Aggrid/CellRenderer/DetailLink'
import NoRowsOverlay from '@/components/Aggrid/Overlay/NoRows'
import PriorityNavigation from '@/components/PriorityNavigation/PriorityNavigation'
import { enumService } from '@/services'
import currencies from '@/views/management/script/currencies'
export default {
  name: 'EnumSelectorModalForAdmin'
  , components: {
    'ag-grid-vue': AgGridVue
    , AlertFeedback: () => import('@/components/AlertFeedback')
    , InProgressModal: () => import('@/components/modal/InProgressModal')
    , EnumModal: () => import('@/components/modal/EnumModal')
    , EnumCurrencySelectorModal: () => import('@/components/modal/EnumCurrencySelectorModal')
    , EnumHistoryModal: () => import('@/components/modal/EnumHistoryModal')
    /* eslint-disable vue/no-unused-components */
    //Renderer
    , detailLinkCellRenderer: DetailLinkCellRenderer
    , rowSelectorCellRenderer: RowSelectorCellRenderer
    , selectorHeaderComponent: SelectorHeaderComponent
    //Editor
    , nameEditor: NameEditor
    //Overlay
    , noRowsOverlay: NoRowsOverlay
    /* eslint-enable vue/no-unused-components */
    , PriorityNavigation
  }
  , props: {
    show: {
      type: Boolean
      , required: true
    }
    //----label----
    , selectorTitle: {
      type: String,
      default: null
    }
  }
  , data: function() {
    return {
      elemId: 'ENUM_SELECTOR_FOR_ADMIN'

      , historyShow: false
      
      , alertObj: {
        msg: null
        , msgDetails: { title: null, list: [] }
        , state: alertStateEnum.SUCCESS

      }
      
      , selectorShow: false
      , enumList: {
        // CompanyTypeEnum: {
        //   addingEnabled: false
        //   , renamingEnabled: false
        //   , removingEnabled: false
        //   , list: [{ code: 'Primary', num: 0 }]
        // }
      }

      , gridInitialized: false
      , activeTab: -1
      , activeTabName: null
      , activeTabValue: {
        addingEnabled: false
        , renamingEnabled: false
        , removingEnabled: false
      }
      
      , gridOptions: null
      , gridApi: null
      , rowData: null //default to null. With null value, ag-grid will show loading overlay.//[]
      , columnDefs: []
      , defaultColDef: {
        sortable: false
        , resizable: true
        , minWidth: 100
        , hide: true
        , lockPinned: true
        , menuTabs: ['columnsMenuTab']
        , suppressKeyboardEvent: (params) => {
          if (params.event.keyCode === 46 
              || params.event.keyCode === 35 || params.event.keyCode == 36 
              || (params.event.keyCode == 37 && params.event.ctrlKey)) {
            return true
          } else if (params.event.keyCode == 13) {
            if (params.event.ctrlKey) {
              return true
            }
            if ((typeof params.colDef.editable == 'boolean' && !params.colDef.editable) || 
                (typeof params.colDef.editable == 'function' &&  !params.colDef.editable({ data: params.data }))) {
              return true
            }
          } else if (params.event.keyCode == 68 && params.event.ctrlKey) { //'D'
            return true
          }
          return false
        }
        , cellRendererParams: { enableReadonlyStyle: true }
      }
      , rowSelectorClicked_allColsSelectedRowIndexList: []
      , rangeSelection: []
      , entitySelection: []

      // , pendingListByFillOperation: []
      // , triggeredByFillOperation: false
      , processValueChangedList: []
      , pendingProcessRequestList: []
      , pendingRequestBatchList: []
      , pendingDeleteCells: []
      
      , ecConfirmDeleteShow: false
      , ecConfirmDeleteEntities: []
      , entityCol: {
        entityId: null
        , entityName: null
        , colId: null
        , applyAll: false
        , tabName: null
      }
      , confirmedDelete: false

      , noRowsMessage: null
      , noRowsOverlayComponentParams: null
      , isCellEditing: false
      , lastOpenColumnMenuParams: null

      , inProgressShow: false
      , inProgressLabel: null
      , inProgressStoppable: false

      , editorShow: false
      , editorCode: null
      , editorNum: -1

      , currencySelectorShow: false
      , isMissingData: false

      , isDataloaded: false
      , isGridReady: false

      , actionInProgress: false
    }
  }
  , created() {
    this.selectorShow = this.show
    this.lastFocusedCell = null
    this.isPasteInProgress = false
    this.triggeredByEditorSuccess = false
    this.previousVScrollPosition = null
    this.showSandboxInfo();
    this.mainColumnId = 'num'
    this.noRowsMessage = this.$t('entity_selector.enum_grid_loading_list')
    this.noRowsOverlayComponentParams = {
      msgFunc: this.prepareNoRowsMessage()
    }
    
    if (this.show) {
      this.prepareData()
    } else {
      this.columnDefs = this.getColumnDefs(null)
    }
  }
  , beforeMount() { 
    const self = this
    this.context = { componentParent: self }
    this.gridOptions = {
      processUnpinnedColumns(params) {
        if (params.api.isDestroyed()) {
          return;
        }
        params.api.setColumnsPinned(['name'], null);
        self.enforcePinnedColumnOrders(params.api);
      },
      onNewColumnsLoaded: function(params) {
        if (params.source == 'api' && params.type == 'newColumnsLoaded') {
          self.enforcePinnedColumnOrders(params.api);
        }
      },
      onColumnVisible: function(params) {
        let fromToolPanel = params.source == "toolPanelUi"
        if (fromToolPanel) {
          let colKey = params.column.colId;
          let columnMenuColumnIndex = params.api
            .getAllGridColumns()
            .findIndex(col => {
              return col === self.lastOpenColumnMenuParams.column;
            });

          params.api.moveColumns([colKey], columnMenuColumnIndex + 1);
        }
      }
      , postProcessPopup: params => {
        if ((params.type == 'columnMenu')) {
          self.lastOpenColumnMenuParams = params;
        }
      }
      , onFirstDataRendered: function(event) {
        //Call sizeColumnsToFit on visible tab only
        event.api.sizeColumnsToFit()
      }
      , onCellValueChanged: function(event) {
        let colId = event.column.colId
        const rowIndex = event.rowIndex
        let newValue = event.newValue
        let oldValue = event.oldValue
        const rowNode = event.api.getDisplayedRowAtIndex(rowIndex)

        if (event.column.colId == self.mainColumnId) {
          newValue = rowNode.data.code
          oldValue = rowNode.data.oldCode //oldCode is added in columndef.valueSetter.
          colId = 'code'
        }

        const payload = {
          colId
          , data: objectClone(rowNode.data)
          , newValue
          , oldValue
          , property: colId
          , entityId: rowNode.id
        }
        
        if (payload.newValue == payload.oldValue) {
          //do nothing
        } else if (self.isPasteInProgress) {
          self.processValueChangedList.push(payload);
        } else {
          self.processValueChangedList.push(payload)
          self.inProgressLabel = self.$t(`update_progress`)
          self.processValueChanged(event.api)
        }
      }
      , onRangeSelectionChanged: function(event) {
        if (event.finished == true) {
          const cellRanges = event.api.getCellRanges()
          const originalRanges = cellRanges.map(i => {
            return {
              rowStartIndex: i.startRow.rowIndex,
              rowEndIndex: i.endRow.rowIndex,
              columns: i.columns.map(j => j.colId)
            }
          })
          if (cellRanges.length > 0) {
            const lastRange = (() => {
                const tmp = cellRanges[cellRanges.length-1]
                const isAsc = tmp.startRow.rowIndex <= tmp.endRow.rowIndex
                return {
                  rowStartIndex: isAsc? tmp.startRow.rowIndex : tmp.endRow.rowIndex,
                  rowEndIndex: isAsc? tmp.endRow.rowIndex : tmp.startRow.rowIndex,
                  columns: tmp.columns.map(i => i.colId),
                  startColumn: tmp.startColumn.colId,
                  orgRowStartIndex: tmp.startRow.rowIndex,
                  orgRowEndIndex: tmp.endRow.rowIndex
                }
              })()
            const lastColumnId = lastRange.startColumn == lastRange.columns[0]? lastRange.columns[lastRange.columns.length-1] : lastRange.columns[0] 
            //Handle drag selection which starts (mouse click press) or finishes (mouse click release) on rowSelector column. 
            //Brief: Include all cells of the selected rows into the cell ranges as if user clicks on rowSelector columns
            if (lastRange.columns.length > 1 && (lastColumnId == 'rowSelector' || lastRange.startColumn == 'rowSelector')) {
              self.rowSelectorChanged({
                cellRanges,
                originalRanges,
                lastRange,
                api: event.api
              })
            } else {
              //Brief: Reshape previous ranges to avoid clashing with last/latest range.
              const lastRangeColumns = lastRange.columns
              const isLastRangeAsc = lastRange.rowStartIndex <= lastRange.rowEndIndex
              const lastRangeStartIndex = isLastRangeAsc? lastRange.rowStartIndex : lastRange.rowEndIndex
              const lastRangeEndIndex = isLastRangeAsc? lastRange.rowEndIndex : lastRange.rowStartIndex

              const cRanges = cellRanges.map(i => {
                return {
                  rowStartIndex: i.startRow.rowIndex,
                  rowEndIndex: i.endRow.rowIndex,
                  columns: i.columns.map(j => j.colId)
                }
              })
              cRanges.splice(cRanges.length - 1, 1)
              const newCellRanges = []
              let hasChanges = false

              //Before reshape, check if last range's start cell clashes with previous cell range.
              //If yes, do nothing (and expect aggrid will do its job to remove cell ranges)
              const startColumnId = lastRange.startColumn
              const startCell = {
                rowStartIndex: lastRange.orgRowStartIndex,
                rowEndIndex: lastRange.orgRowStartIndex
              }
              let isStartCellClashed = false
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                const isRowClashed = startCell.rowStartIndex >= cellRangeStartIndex && startCell.rowEndIndex <= cellRangeEndIndex
                const columnClashed = range.columns.filter(i => i == startColumnId).length > 0
                if (isRowClashed && columnClashed) {
                  isStartCellClashed = true
                  break
                }
              }
              if (isStartCellClashed) {
                return
              }

              //Reshape previous ranges to avoid clashing with last/latest range.
              for (const range of cRanges) {
                const isCellRangeAsc = range.rowStartIndex <= range.rowEndIndex
                const cellRangeStartIndex = isCellRangeAsc? range.rowStartIndex : range.rowEndIndex
                const cellRangeEndIndex = isCellRangeAsc? range.rowEndIndex : range.rowStartIndex
                const isRowClashed = (lastRangeStartIndex >= cellRangeStartIndex && lastRangeStartIndex <= cellRangeEndIndex) ||
                                     (cellRangeStartIndex >= lastRangeStartIndex && cellRangeStartIndex <= lastRangeEndIndex)
                const clashedColumns = range.columns.filter(i => lastRangeColumns.includes(i))                
                if (!isRowClashed || clashedColumns.length == 0) {
                  newCellRanges.push(range)
                  continue
                }
                
                //Reshape cell range if it clash with lastRange.
                const splitTopNeeded = cellRangeStartIndex < lastRangeStartIndex
                const splitBottomNeeded = cellRangeEndIndex > lastRangeEndIndex
                const firstClashedCol = clashedColumns[0]
                const splitLeftNeeded = range.columns.findIndex(i => i == firstClashedCol) != 0
                const lastClashedCol = clashedColumns[clashedColumns.length-1]
                const splitRightNeeded = range.columns.findIndex(i => i == lastClashedCol) != range.columns.length-1

                if (splitTopNeeded) {
                  newCellRanges.push({
                    rowStartIndex: cellRangeStartIndex,
                    rowEndIndex: lastRangeStartIndex-1,
                    columns: objectClone(range.columns)
                  })
                  hasChanges = true
                }

                if (splitRightNeeded) {
                  const startColIdx = range.columns.findIndex(i => i == lastClashedCol)+1
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(startColIdx)
                  })
                  hasChanges = true
                }

                if (splitBottomNeeded) {
                  newCellRanges.push({
                    rowStartIndex: lastRangeEndIndex+1,
                    rowEndIndex: cellRangeEndIndex,
                    columns: objectClone(range.columns)
                  })
                  hasChanges = true
                }

                if (splitLeftNeeded) {
                  const sliceLen = range.columns.findIndex(i => i == firstClashedCol)
                  newCellRanges.push({
                    rowStartIndex: splitTopNeeded? lastRangeStartIndex : cellRangeStartIndex,
                    rowEndIndex: splitBottomNeeded? lastRangeEndIndex : cellRangeEndIndex,
                    columns: range.columns.slice(0, sliceLen)
                  })
                  hasChanges = true
                }
              }
              
              //Merge last range to any existing range when condition met.
              //Conditions: 1) Matched rows and column(s) in sequence, or 
              //            2) Matched columns and row(s) in sequence.
              let hasRangeMerged = false
              if (newCellRanges.length > 0) {
                const allColumns = event.api.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector').map(i => i.colId)
                const lastRowStartColIndex = allColumns.findIndex(i => i == lastRangeColumns[0])
                const lastRowEndColIndex = allColumns.findIndex(i => i == lastRangeColumns[lastRangeColumns.length - 1])
                const cloned = objectClone(newCellRanges)
                const newRanges = []
                for (const cRange of cloned) {
                  const startColIndex = allColumns.findIndex(i => i == cRange.columns[0])
                  const endColIndex = allColumns.findIndex(i => i == cRange.columns[cRange.columns.length - 1])
                  const isRowIndexMatched = cRange.rowStartIndex == lastRangeStartIndex && cRange.rowEndIndex == lastRangeEndIndex
                  const isColumnMatched = startColIndex == lastRowStartColIndex && endColIndex == lastRowEndColIndex
                  const isRowInSequence = cRange.rowStartIndex -1 == lastRange.rowEndIndex || cRange.rowEndIndex + 1 == lastRange.rowStartIndex
                  const isColumnInSequence = startColIndex - 1 == lastRowEndColIndex || endColIndex + 1 == lastRowStartColIndex
                  if (isRowIndexMatched && isColumnInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex
                      , rowEndIndex: lastRangeEndIndex
                      , columns: lastRowStartColIndex < startColIndex? [...lastRangeColumns, ...cRange.columns] : [...cRange.columns, ...lastRangeColumns]
                    })
                    hasRangeMerged = true
                    continue
                  } else if (isColumnMatched && isRowInSequence) {
                    newRanges.push({
                      rowStartIndex: lastRangeStartIndex < cRange.rowStartIndex? lastRangeStartIndex : cRange.rowStartIndex
                      , rowEndIndex: lastRangeEndIndex > cRange.rowEndIndex? lastRangeEndIndex : cRange.rowEndIndex
                      , columns: lastRangeColumns
                    })
                    hasRangeMerged = true
                    continue
                  }
                  newRanges.push(cRange)
                }
                if (hasRangeMerged) {
                  newCellRanges.splice(0, newCellRanges.length, ...newRanges)
                }
              }
              
              //hasChanges flag is important to avoid infinite loop. 
              //any addCellRange() call will trigger onRangeSelectionChange() event.
              //Don't call addCellRange() when no change is required.
              if (hasChanges || hasRangeMerged) {
                //Adding last range when hasRangeMerged is false. 
                //Details: If hasRangeMerged is true, the last range has been merged to one of the previous range.
                if (!hasRangeMerged) {
                  newCellRanges.push({
                    rowStartIndex: lastRange.rowStartIndex,
                    rowEndIndex: lastRange.rowEndIndex,
                    columns: lastRange.columns
                  })
                }
                event.api.clearRangeSelection()
                for (const ncRange of newCellRanges) {
                  event.api.addCellRange(ncRange)
                }
              } else {
                //When thing settles down, update entitySelection variable.
                let selectedEntities = []
                for (const oRange of originalRanges) {
                  const startRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowEndIndex : oRange.rowStartIndex
                  const endRowIdx = oRange.rowStartIndex > oRange.rowEndIndex? oRange.rowStartIndex : oRange.rowEndIndex
                  
                  for (let i = startRowIdx; i <= endRowIdx; i++) {
                    const rowNode = event.api.getDisplayedRowAtIndex(i)
                    
                    if (rowNode == null || rowNode.data == null) {
                      continue
                    }

                    if (rowNode.data) {
                      //Treat any cell selection is ag-Grid-AutoColumn cell selection.
                      selectedEntities.push({ 
                          num: rowNode.data.num
                        , code: rowNode.data.code
                        , colId: self.targetColumnId
                        , rowIndex: rowNode.rowIndex
                      })
                    }
                  }
                }
                
                //Rearrange order of the tasks.
                //Tasks without rowIndex will be pushed to the bottom of the list. Theorectically, all tasks should have rowIndex property.
                //The first task/summary task will be target parent for new task creation.
                selectedEntities.sort(function( a, b ) {
                  if (a.rowIndex == null && b.rowIndex == null) {
                    return 0
                  }
                  if (b.rowIndex == null || a.rowIndex < b.rowIndex){
                    return -1
                  }
                  if (a.rowIndex == null || a.rowIndex > b.rowIndex){
                    return 1
                  }
                  return 0
                })
                
                self.entitySelection.splice(0, self.entitySelection.length, ...selectedEntities)
              }
            }
          } else {
            //Clean up entitySelection when range selection is empty.
            self.entitySelection.splice(0, self.entitySelection.length)
          }
          event.api.refreshHeader()
        }
        
      }
      , onPaginationChanged: function(/** event */) {
        //Don't reset previous vertical scroll when it is triggerred by editor success event
        if (self.triggeredByEditorSuccess) {
          self.triggeredByEditorSuccess = false
        } else {
          self.previousVScrollPosition = null
        }
        
      }
    }
  }
  , mounted() {
    
  }
  , beforeDestroy() {
    this.context = null
    this.lastFocusedCell = null
    this.gridApi = null
    this.triggeredByEditorSuccess = false
    this.previousVScrollPosition = null
  }
  , watch: {
    async show(newValue) {
      this.resetAlert()
      this.selectorShow = newValue
      this.entitySelection.splice(0, this.entitySelection.length)
      this.rangeSelection.splice(0, this.rangeSelection.length)
      this.lastFocusedCell = null
      this.isCellEditing = false
      this.editedNum = null
      // this.rowData = []
      this.isMissingData = false
      this.resetActiveTab()
      this.showSandboxInfo();
      if (newValue) {
        this.prepareData()
      }
    }
    , activeTab(newValue) {
      if (newValue < 0) {
        return
      } 
      this.resetAlert()
      this.showSandboxInfo();
      if (Object.keys(this.enumList).length == 0) {
        this.resetActiveTab()
        return
      }
      
      this.updateTabLink(newValue)
      this.activeTabName = Object.keys(this.enumList)[newValue]
      this.activeTabValue = this.enumList[this.activeTabName] != null && Array.isArray(this.enumList[this.activeTabName].list)? 
                            this.enumList[this.activeTabName] : { addingEnabled: false, renamingEnabled: false, removingEnabled: false, list: [], disabledList: [] }

      if (this.gridInitialized) {
        const api = this.gridApi
        if (api != null) {
          this.feedDataToGrid(api)
        }
      }
    }
    , gridInitialized(newValue) {
      if (newValue) {
        setTimeout(() => {
          this.noRowsMessage = null
        }, 100)
      }
    }
  }
  , computed: {
    disableDelete() {
      return this.actionInProgress || this.entitySelection.length < 1 || this.entitySelection.filter(i => {
        return this.activeTabValue != null && this.activeTabValue.list != null 
            && this.activeTabValue.list.find(j => j.code == i.code && j.redacted == true) != null
      }).length == this.entitySelection.length
    }
    , disableAdd() {
      return this.actionInProgress
    }
    , disableEdit() {
      return this.actionInProgress || this.entitySelection.length != 1 || 
          (this.activeTabValue != null && this.activeTabValue.list != null 
            && this.activeTabValue.list.find(i => i.code == this.entitySelection[0].code && i.redacted == true) != null)
    }
    , disableReorder() {
      return this.actionInProgress || this.entitySelection.length != 1
    }
    , overlayLoadingTemplate() {
      return `<span class='grid-overlay'><div class="mr-1 spinner-grow spinner-grow-sm text-dark"></div>${ this.$t('grid.loading_list') }</span>`;
    }
    , selectorTitleLabel() {
      return this.selectorTitle != null? this.selectorTitle : this.$t(`entity_selector.enum_selector`)
    }
    , editorTitleLabel() {
      return this.activeTabName != null? this.$t(`enum.title_${this.editorNum == -1? 'new':'edit'}`, [this.$t(`enum.${this.activeTabName}`).toLowerCase()]) : ''
    }
    , ecConfirmDeleteStatement() {
      if (this.entityCol != null && this.entityCol.tabName != null) {
        return this.$t(`entity_selector.enum_entitycol_delete`, [this.$t(`enum.${this.entityCol.tabName}`).toLowerCase(), this.entityCol.entityName])
      } else {
        return ''
      }
    }
    , ecShowApplyAllCheckbox() {
      return this.ecConfirmDeleteEntities.length > 0
    }
    , allowManage() {
      return this.activeTabName != 'CurrencyEnum'? true : false
    }
  }
  , methods: {
    async prepareData() {

      await this.$store.dispatch('data/enumPermission').then(response => {
        if (response != null 
            && response.jobCase != null 
            && response[response.jobCase] != null) {
          const propertyList = response[response.jobCase]
          const keys = Object.keys(propertyList);
          for (const k of keys) {
            const array = propertyList[k]
            if (this.enumList[k] == null) {
              this.enumList[k] = {}
            }
            this.$set(this.enumList[k], 'addingEnabled', array.includes('allowAdding'))
            this.$set(this.enumList[k], 'renamingEnabled', k == 'CurrencyEnum' == k? false : array.includes('allowRename'))
            this.$set(this.enumList[k], 'removingEnabled', array.includes('allowDisable'))
          }
        }
      }).catch(e => {
        console.error(e); //eslint-disable-line no-console
        this.isMissingData = true;
      })

      await this.$store.dispatch('data/enumList').then(response => {
        if (response != null) {
          if (response.jobCase != null && response[response.jobCase] != null) {
            const propertyList = response[response.jobCase]
            const keys = Object.keys(propertyList);
            for (const k of keys) {
              const obj = propertyList[k]
              const codes = Object.keys(obj)
              const list = []
              const disabledList = []
              for (const c of codes) {
                if (obj[c] < 0) {
                  disabledList.push({ code: c, num: obj[c] })
                } else {
                  list.push({ code: c, num: obj[c] })
                }
              }

              if (this.enumList[k] == null) {
                this.$set(this.enumList, k, { list, disabledList, redacted: [] })
              } else {
                this.$set(this.enumList[k], 'list', list)
                this.$set(this.enumList[k], 'disabledList', disabledList)
                this.$set(this.enumList[k], 'redacted', [])
              }
            }
          }

          //Fill up currency name property
          if (this.enumList.CurrencyEnum != null && Array.isArray(this.enumList.CurrencyEnum.list)) {
            const currencyList = this.enumList.CurrencyEnum.list
            for (const item of currencyList)   {
              const found = currencies.find(i => i.code === item.code)
              if (found != null) {
                item.name = found.name
              }
            }
          }

          if (Array.isArray(response.redacted) && response.redacted.length > 0) {
            const redactedList = response.redacted
            for (const r of redactedList) {
              if (r.length < 2) {
                continue
              }
              
              const tokens = r.split('.')
              const enumName = tokens[0]
              const redactedValue = tokens[1]
              if (this.enumList[enumName] == null) {
                this.enumList[enumName] = { redacted: [redactedValue], list: [], disabledList: [] }
              } else {
                if (Array.isArray(this.enumList[enumName].redacted)) {
                  this.enumList[enumName].redacted.push(redactedValue)
                } else {
                  this.enumList[enumName].redacted = [redactedValue]
                }

                if (Array.isArray(this.enumList[enumName].list) && this.enumList[enumName].list.length > 0) {
                  const found = this.enumList[enumName].list.find(i => i.code === redactedValue)
                  if (found != null) {
                    found.redacted = true
                  }
                }
              }
            }
          }

        }
      }).catch(e => {
        console.error(e); //eslint-disable-line no-console
        this.isMissingData = true;
      })

      if (this.isGridReady && Object.keys(this.enumList).length > 0) {
        this.activeTab = 0
      } else {
        this.resetActiveTab()
      }
      this.isDataloaded = true;
    }
    , selectorNavClick(event) {
      /**
       * Update activeTab with the user choice.
       */
      const liElem = event.srcElement.closest('li')
      const activeName = liElem.getAttribute('name')
      
      let foundIndex = Object.keys(this.enumList).findIndex(i => i == activeName)
      if (foundIndex == -1) {
        foundIndex = 0
      }
      this.activeTab = foundIndex
    }
    , async updateTabLink(activeIndex) {
      if (activeIndex < 0) {
        return
      }
      //Update the active state/style of links.
      const navbarElem = this.$refs['selector-nav']
      if (navbarElem != null) {
        const childs = navbarElem.$el.querySelectorAll('li')
        for (let i = 0; i < childs.length; i++) {
          if (activeIndex == i) {
            childs[i].classList.add('active')
          } else if(childs[i] != null && childs[i].classList != null) {
            childs[i].classList.remove('active')
          }
        }
      }
    }
    , hidden() {
      this.alert = null
      this.inProgressShow = false;
      this.$emit('update:show', false)
      this.$emit('cancel')
    }
    , openEditor(isNew /**, tabName */) {

      if (this.activeTabName === 'CurrencyEnum') {
        //Open currencySelectorModal when the activeTab is 'CurrencyEnum'
        //Ignore isNew param because currencyEnum only has add new action
        this.currencySelectorShow = true
        this.resetAlert()
        return
      }

      if(isNew) {
        this.editorNum = -1
        this.editorCode = null
      } else {
        this.editorNum = this.entitySelection[0].num
        this.editorCode = this.entitySelection[0].code
      }
      this.editorShow = true
      this.resetAlert()
    }
    , rowDelete(tabName) {
      this.resetAlert()
      const targetColEntities = []
      this.confirmedDelete = false //reset value

      for (const item of this.entitySelection) {
        const found = this.activeTabValue.list.find(i => i.num == item.num && i.redacted != true)
        if (found == null) {
          continue
        }
        targetColEntities.push({
          num: found.num
          , code: found.code
          , colId: this.mainColumnId
          , name: found.name != null? found.name : null
          , tabName
        })
      }

      if (targetColEntities.length > 0) {
        //Prepare data for entity delete confirmation dialog
        this.ecConfirmDeleteEntities = targetColEntities
        this.prepareTargetColConfirmDeleteDialog()
      }
      
    }
    , getColumnDefs(tabName) {
      const self = this
      let colDefs = [
        {
          headerName: this.$t('value')
          , field: 'num'
          , cellRenderer: 'detailLinkCellRenderer'
          , cellRendererParams: {
            label: 'code'
            , tabName: tabName
          }
          , cellEditor: 'nameEditor'
          , cellEditorParams: {
            customProp: 'code'
          }
          , checkboxSelection: false
          , pinned: 'left'
          , lockPosition: 'left'
          , lockVisible: true
          , minWidth: 150
          , hide: false
          , sort: null
          , editable: (params) => { 
            return tabName != null? this.enumList[tabName].renamingEnabled && params.data.redacted != true : false 
          }
          , valueSetter: function(params) {
            const newValue = params.newValue != null? params.newValue.trim() : ''
            const oldValue = objectClone(params.data.code)
            if (newValue !== '' && newValue != oldValue) {
              self.$set(params.data, 'oldCode', oldValue)
              params.data.code = newValue
              return true
            }
            return false
          }
        }
      ]
      if (tabName == 'CurrencyEnum') {
        colDefs = [
          {
            headerName: this.$t('name')
            , field: 'name'
            , checkboxSelection: false
            , pinned: 'left'
            , lockPosition: 'left'
            , lockVisible: true
            , minWidth: 150
            , hide: false
            , sort: null
            , editable: false
          }
          , {
            headerName: self.$t('value')
            , field: 'code'
            // , cellRenderer: 'genericCellRenderer'
            , hide: false
            , editable: false
          }
        ]
      }
      return colDefs
    }
    , onGridReady(params) {
      this.gridApi = params.api
      this.gridInitialized = true
      if (this.isDataLoaded && this.activeTab < 0) {
        this.activeTab = 0
      }
      this.isGridReady = true
    }
    , feedDataToGrid(api, callback=null) {
      let fallbackToDefault = true
      if (this.enumList != null && this.activeTab > -1 && this.activeTabName != null && this.activeTabValue != null) {
        const keys = Object.keys(this.enumList)
        if (keys.length > 0) {
          fallbackToDefault = false
          this.columnDefs = this.getColumnDefs(this.activeTabName)
          if (Array.isArray(this.activeTabValue.list)) {
            if (api != null) {
              const d = JSON.parse(JSON.stringify(this.activeTabValue.list));
              api.setGridOption('rowData', d);
            }
            
          } else {
            if (api != null) {
              api.setGridOption('rowData', []);
            }
          }
        }
      }
      
      if (fallbackToDefault) {
        this.columnDefs = this.getColumnDefs(null)
        // this.rowData = []
        if (this.gridApi != null) {
          this.gridApi.setGridOption('rowData', []);
        }
      }
      
      if (api != null) {
        
        api.clearRangeSelection()
        setTimeout(() => {
          api.sizeColumnsToFit()
        }, 0)
        
      }

      if (typeof callback == 'function') {
        callback()
      }
    }
    , resetAlert({ msg=null, details=null, detailTitle=null, alertState=alertStateEnum.SUCCESS } = {}) {
      this.alertObj.msg = msg
      this.alertObj.state = alertState
      this.alertObj.msgDetails.title = detailTitle
      const list = this.alertObj.msgDetails.list
      if (details != null && Array.isArray(details)) {
        list.splice(0, list.length, ...details)
      } else {
        list.splice(0, list.length)
      }
    }
    , navigateToNextCell(params) {
      const previousCellPosition = params.previousCellPosition
      const nextCellPosition = params.nextCellPosition
      if (nextCellPosition == null) {
        return previousCellPosition
      }
      //Clear range selection when move focus from cell to header.
      if (nextCellPosition.rowIndex < 0 && previousCellPosition.rowIndex > -1) {
        params.api.clearRangeSelection()
      }
      // Stay in previousCell when next Cell belonged to rowSelector column.
      if (nextCellPosition.column.colId === 'rowSelector') {
        return previousCellPosition
      }
      return nextCellPosition
    }
    , tabToNextCell(params) {
      //Fix for the bug: Multiple tabToNextCell events are fired when tab in cell editing.
      const curColId = params.previousCellPosition.column.colId
      const columns = params.api.getAllDisplayedColumns().filter(i => i.colId != 'rowSelector')
      let rowIndex = params.previousCellPosition.rowIndex
      let index = columns.findIndex(i => i.colId == curColId)
      let nextColIdx
      if (index == 0 && params.backwards) {
        rowIndex -= 1
        nextColIdx = columns.length - 1
      } else if (index == columns.length-1 && !params.backwards) {
        rowIndex += 1
        nextColIdx = 0
      } else if (params.backwards) {
        nextColIdx = index - 1
      } else {
        nextColIdx = index + 1
      }
      const column = columns[nextColIdx]

      if  (this.tabTimeoutId == null) {
        this.tabRowIndex = rowIndex
        this.tabColumn = column
        this.tabTimeoutId = setTimeout(() => {
          this.tabRowIndex = null
          this.tabColumn = null
          this.tabTimeoutId = null
        }, 0)
        setTimeout(() => {
          params.api.clearRangeSelection()
          params.api.setFocusedCell(rowIndex, column.colId)
          params.api.addCellRange({
            rowStartIndex: rowIndex
            , rowEndIndex: rowIndex
            , columns: [column.colId]
          })
          params.api.ensureIndexVisible(rowIndex, null)
          params.api.ensureColumnVisible(column.colId, 'auto')
        }, 0)
        return params.previousCellPosition
      }
      //Skip cell navigation. 
      //Pro: to break infinite loop
      //Cons: will cause cell focus appear in the top left html element (e.g. Company logo)
      //Hence, a really short timeout duration is used to minimise the appearance of the cell focus.
      return null
    }
    , cellFocused(event) {
      if (event.rowIndex != null && event.column != null) {
        this.lastFocusedCell = { rowIndex: event.rowIndex, colId: event.column.colId }  
      }
    }
    , onCellKeyDown(params) {
      if (params.event.key == 'Delete') {
        const tabName = Object.keys(this.enumList)[this.activeTab]
        if (this.enumList[tabName].removingEnabled) {
          const cellRanges = params.api.getCellRanges()
          //Prepare cell information
          const targetColEntities = []
          const processedCells = [] //Used to elimate duplicate records.

          const tabName = Object.keys(this.enumList)[this.activeTab]
          const mainColId = this.mainColumnId
          
          
          for (const cRange of cellRanges) {
            const startRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.startRow.rowIndex : cRange.endRow.rowIndex
            const lastRowIdx = cRange.startRow.rowIndex <= cRange.endRow.rowIndex? cRange.endRow.rowIndex : cRange.startRow.rowIndex 
            
            const columns = cRange.columns
            for (let idx = startRowIdx; idx <= lastRowIdx; idx++) {
              const rowNode = params.api.getDisplayedRowAtIndex(idx)
              for (const column of columns) {
                const colId = column.colId
                const rowId = rowNode.id
                const found = processedCells.find(i => rowId == i.rowId && colId == i.colId)
                if (found != null) {
                  continue //Duplicated cell is found. Process to next iteration.
                }
                processedCells.push({ rowId, colId })

                //Only handle 'targetColumn' cell
                //Brief: Delete targetColumn cell means remove the whole row
                if (mainColId == colId || this.activeTabName == 'CurrencyEnum' && (colId == 'name' || colId == 'code')) {
                  if (rowNode.data == null || rowNode.data.code == null || rowNode.data.redacted == true) {
                    continue //Defensive code: skip when code is invalid
                  }
                  
                  targetColEntities.push({
                    num: rowNode.id
                    , code: rowNode.data.code
                    , colId
                    , name: rowNode.data.name != null? rowNode.data.name : null
                    , tabName
                  })
                  continue
                }
                
              }
            }
          }
          
          if (targetColEntities.length > 0) {
            this.confirmedDelete = false
            //Prepare data for row delete confirmation dialog
            this.ecConfirmDeleteEntities = targetColEntities
            this.prepareTargetColConfirmDeleteDialog()
          }
        }
      } else if (params.event.keyCode == 13) {
        //Navigate to next cell when user press enter on the read-only cell.
        let editable = false
        const api = params.api
        if (api == null) {
          return
        }
        const fRowIndex = params.rowIndex
        const column = params.column
        if (typeof column.colDef.editable == 'boolean') {
          editable = column.colDef.editable
        } else if (typeof column.colDef.editable == 'function') {
          const data = params.data
          editable = column.colDef.editable({ data })
        }
        if (!editable) {
          let rowIndex
          if (params.event.shiftKey) {
            rowIndex = fRowIndex - 1
            if (rowIndex < 0) {
              rowIndex = 0
            }
          } else {
            rowIndex = fRowIndex + 1
            const lastRowIndex = api.getDisplayedRowCount() - 1
            if (rowIndex > lastRowIndex) {
              rowIndex = lastRowIndex
            }
          }
          //Navigate to next cell below when user press enter on ready only cell.
          if (api.getDisplayedRowAtIndex(rowIndex) != null) {
            this.navigateCellTo(api, rowIndex, column.colId, null)
          }
        }
      } else if (params.event.key == '.') { //Fixed the issue of cell editing is not triggered by '.' key
        const editingCells = params.api.getEditingCells()
        if (editingCells.length == 0) {
          params.api.setFocusedCell(params.rowIndex, params.column.colId);
          params.api.startEditingCell({
            rowIndex: params.rowIndex,
            colKey: params.column.colId,
            key: params.event.key
          });
        }
      }
    }
    , ecConfirmDeleteOk() {
      //Defensive code against null reference
      if (this.gridApi == null) {
        setTimeout(() => {
          this.prepareTargetColConfirmDeleteDialog()
        }, 300)
      }
      this.confirmedDelete = true

      const entities = [{ 
        entityId: this.entityCol.entityId
        , tabName: this.entityCol.tabName 
        , colId: this.entityCol.colId
      }]
      if (this.entityCol.applyAll == true) {
        entities.push(...this.ecConfirmDeleteEntities.map(i => {
          return {
            entityId: i.num
            , colId: i.colId
            , tabName: i.tabName
          }
        }))
        this.ecConfirmDeleteEntities.splice(0, this.ecConfirmDeleteEntities.length)
      }

      
      // const list = this.enumList[tabName].list
      // const disabledList = this.enumList[tabName].disabledList
      const toBeRemoved = []
      for (const entity of entities) {
        const list = this.enumList[entity.tabName].list
        const disabledList = this.enumList[entity.tabName].disabledList
        const index = list.findIndex(i => i.num == entity.entityId)
        if (index == -1) {
          continue
        }
        const found = this.gridApi.getRowNode(entity.entityId)
        if (found) {
          toBeRemoved.push(found.data)
        }
        const toDisableList = list.splice(index, 1)
        toDisableList.forEach(i => {
          i.num = -Math.abs(i.num) 
        })
        disabledList.push(...toDisableList)
      }
      
      setTimeout(() => {
        this.prepareTargetColConfirmDeleteDialog()
      }, 300)
    }
    , prepareTargetColConfirmDeleteDialog() {
      if (this.ecConfirmDeleteEntities == null || this.ecConfirmDeleteEntities.length == 0) {
        this.deleteCell()
        return
      }
      
      const entity = this.ecConfirmDeleteEntities.shift()
      this.entityCol.entityId = entity.num
      this.entityCol.entityName = entity.name != null? `${entity.name} (${entity.code})` : entity.code
      this.entityCol.applyAll = false
      this.entityCol.colId = entity.colId
      this.entityCol.tabName = entity.tabName
      this.inProgressShow = false
      this.ecConfirmDeleteShow = true
    }
    , ecConfirmDeleteCancel() {
      this.deleteCell()
    }
    , async deleteCell() {
      if (!this.confirmedDelete) {
        this.reloadData(() => {
          this.actionInProgress = false
        })
        return
      }

      this.updateEnumToBackend(this.activeTabName, null, null, { disable: true })
    }
    , prepareNoRowsMessage() {
      return () => {
          if (this.noRowsMessage != null) {
          return this.noRowsMessage;  
        }
        return this.$t('grid.no_data');
      }
    }
    , onCellEditingStarted(/** event **/) {
      this.isCellEditing = true
    }
    , onCellEditingStopped(/** event **/) {
      this.isCellEditing = false
    }
    , resetActiveTab({skipTabIndex=false}={}) {
      if (!skipTabIndex) {
        this.activeTab = -1  
      }
      this.activeTabName = null
      this.activeTabValue = {
        addingEnabled: false
        , renamingEnabled: false
        , removingEnabled: false
        , list: []
        , disabledList: []
      }
    }
    , getTabLabel(name) {
      return this.$t(`enum.${name}`)
      // let s = name
      // if (s.endsWith('Enum')) {
      //   s = s.substring(0, s.length-'Enum'.length)
      // }
      // return s.replace(/([A-Z]+)/g, ' $1').trim()
    }
    , async processValueChanged(api, { customProgressLabel=null } = {}) {
      
      if (customProgressLabel != null) {
        this.inProgressLabel = customProgressLabel
      } else {
        this.inProgressLabel = this.$t(`entity_selector.enum_update_progress`)
      }
      this.inProgressShow = true
      
      //Prepare to call compose api request and end the session
      if (this.processValueChangedList.length == 0) {
        if (this.pendingProcessRequestList.length > 0) {
          this.processChange(this.pendingProcessRequestList.splice(0, this.pendingProcessRequestList.length), ()=> {
            this.inProgressShow = false;
            this.actionInProgress = false;
          })
        } else {
          this.inProgressShow = false
        }
        return
      }

      do {
        let currentItem = this.processValueChangedList.shift()
        const property = currentItem.property
        const newValue = currentItem.newValue
        let entityId = currentItem.entityId

        if (property == 'code') {
          this.pendingProcessRequestList.push({ num: entityId, code: newValue })
        }
      } while(this.processValueChangedList.length > 0);

      if (this.processValueChangedList.length == 0) {
        this.inProgressShow = false;
        //Last, call itself again to begin next iteration
        this.processValueChanged(api);
      }
    }
    , moveRow(tabName, moveUp=false) {
      let api = this.gridApi
      if (api == null) {
        return
      }
      
      const cell = api.getFocusedCell()
      if (cell == null) {
        return
      }

      const activeList = this.activeTabValue.list

      const rowIndex = cell.rowIndex
      if (moveUp && rowIndex < 1) {
        return
      } else if (!moveUp && rowIndex >= activeList.length - 1) {
        return
      }

      let tmp = activeList[rowIndex]
      const targetRowIndex = moveUp? rowIndex - 1 : rowIndex + 1
      activeList[rowIndex] = activeList[targetRowIndex]
      activeList[targetRowIndex] = tmp

      const colId = cell.column.colId
      api.clearRangeSelection()
      api.clearFocusedCell()
      api.setGridOption('rowData', objectClone(activeList))

      api.addCellRange({
        rowStartIndex: targetRowIndex,
        rowEndIndex: targetRowIndex,
        columns: api.getColumnState().filter(i => i.hide != true && i.colId != 'rowSelector').map(i => i.colId)
      })
      api.setFocusedCell(targetRowIndex, colId, null)

      this.updateEnumToBackend(tabName)
    }
    , async updateEnumToBackend(tabName, successCallback=null, errorCallback=null, { add=false, change=false, remove=false, disable=false }={}) {
      this.actionInProgress = true
      const enumObj = this.enumList[tabName]
      const list = objectClone(enumObj.list)
      list.push(...enumObj.disabledList)

      const toUpdateObj = {}
      for(const l of list) {
        toUpdateObj[l.code] = l.num
      }
      
      await enumService.update(tabName, toUpdateObj, { add, change, remove, disable })
      .then(() => {
        this.reloadData(() => {
          if (typeof successCallback == 'function') {
            successCallback()
          } else {
            this.actionInProgress = false
          }
        })
      })
      .catch(e => {
        this.reloadData(() => {
          if (typeof errorCallback == 'function') {
            errorCallback()
          } else {
            this.actionInProgress = false
          }
        })
        this.httpAjaxError(e)
      })
    }
    , httpAjaxError(e) {
      console.error(e) //eslint-disable-line no-console
      const response = e.response;
      let alertMsg = this.$t('error.internal_server')
      if (response && 403 === response.status) {
        alertMsg = this.$t('error.authorize_action')
      }
      this.resetAlert({ msg: alertMsg, alertState: alertStateEnum.ERROR })
      this.scrollToTop();
    }
    , scrollToTop() {
      setTimeout(() => {
        let elem = document.querySelector(`.${this.elemId}`);
        elem = elem != null? elem.querySelector('.modal-body') : null;
        elem = elem != null? elem.firstChild : null;
        if (elem != null && elem.scrollIntoView) {
          elem.scrollIntoView({ behavior: 'smooth' });
        }
      }, 0);
    }
    , editorOk({ code, num }) {
      if (this.gridApi == null || this.gridApi.isDestroyed()) {
        return
      }
      this.triggeredByEditorSuccess = true
      this.previousVScrollPosition = this.gridApi.getVerticalPixelRange().top
      this.processChange([{ code, num }])
    }
    , processChange(changeList, callback=null) {
      this.resetAlert()
      if (changeList.length == 0) {
        if (typeof callback == 'function') {
          callback()
        }
        return
      }
      const actions = {
        add: false
        , change: false
        , remove: false
        , disable: true
      }
      
      let errorMsg = null
      const reversedList = []
      for (const i of changeList) {
        reversedList.unshift(i)
      }
      //Deduplicate identical code: keep the first one and discard the rest
      const toUpdateObj = {}
      for(const l of reversedList) {
        toUpdateObj[l.code] = l.num
      }


      let injectPos = -1

      for (const code in toUpdateObj) {
        const num = toUpdateObj[code]
        let found = this.activeTabValue.redacted.find(i => i == code)
        if (found) {
          errorMsg = this.$t('enum.error_reserved_code', [code])
          break
        }

        found = this.activeTabValue.list.find(i => i.code == code && i.num != null)
        if (found) {
          errorMsg = this.$t('enum.error_duplicate_code', [code])
          break
        }

        let foundIndex = this.activeTabValue.disabledList.findIndex(i => i.code == code)
        if (foundIndex != -1) {
          actions.change = true
          if (this.activeTabName == 'CurrencyEnum') {
            this.activeTabValue.disabledList.splice(foundIndex, 1)
          } else {
            found = this.activeTabValue.disabledList[foundIndex]
            found.code = `${found.code}_1`
          }
        }

        if (num == null) {
          actions.add = true
          found = this.activeTabValue.list.find(i => i.code == code)
          if (!found) {
            //Find the new num value for new item
            const fullList = [...this.activeTabValue.list , ...this.activeTabValue.disabledList.map(i => { return { num: Math.abs(i.num) }})].map(i => i.num)
            let max = 0
            let min = 0
            if (fullList.length > 0) {
              max = Math.max(...fullList);
              min = Math.min(...fullList);
            }
            
            let newNumValue = max+1
            if (min != max) {
              for (let i=min; i <= max; i++) {
                if (!fullList.includes(i)) {
                  newNumValue = i
                  break
                }
              }
            }
            
            //Add new item after the selected row
            if (this.editorNum != -1) {
              if (injectPos == -1) {
                const index = this.activeTabValue.list.findIndex(i => i.num == this.editorNum)
                injectPos = index + 1
              } else {
                injectPos = injectPos + 1
              }
              this.activeTabValue.list.splice(injectPos, 0, { code, num: newNumValue })
            } else {
              this.activeTabValue.list.push({ code, num: newNumValue })
            }
          }
        } else if (this.activeTabName == 'CurrencyEnum') {
          actions.add = true
          //Add new item after the selected row
          const f = currencies.find(i => i.code == code)
          const name = f != null? f.name : ''
          if (this.editorNum != -1) {
            if (injectPos == -1) {
              const index = this.activeTabValue.list.findIndex(i => i.num == this.editorNum)
              injectPos = index + 1
            } else {
              injectPos = injectPos + 1
            }
            this.activeTabValue.list.splice(injectPos, 0, { code, num, name })
          } else {
            this.activeTabValue.list.push({ code, num, name })
          }
        } else {
          actions.change = true
          //Update existing item's code value
          found = this.activeTabValue.list.find(i => i.num == num)
          if (found) {
            found.code = code
          }
        }
      }

      if (errorMsg != null) {
        this.resetAlert({ msg: errorMsg, alertState: alertStateEnum.ERROR })
        this.reloadData(() => {
          if (typeof callback == 'function') {
            callback()
          } else {
            this.actionInProgress = false
          }
        })
        return
      }

      this.updateEnumToBackend(this.activeTabName, () => {
        setTimeout(() => {
          if (actions.add && this.gridApi != null) {
            const index = injectPos == -1? this.activeTabValue.list.length - 1 : injectPos
            this.gridApi.ensureIndexVisible(index, null)
            const colId = this.activeTabName == 'CurrencyEnum'? 'name' : this.mainColumnId
            this.gridApi.clearRangeSelection()
            this.gridApi.addCellRange({
              rowStartIndex: index
              , rowEndIndex: index
              , columns: [colId]
            })
          }
          if (typeof callback == 'function') {
            callback()
          } else {
            this.actionInProgress = false
          }
        }, 100)
      }, callback, actions)
    }
    , onPasteStart(/** params */) {
      this.isPasteInProgress = true;
    }
    , onPasteEnd(params) {
      this.isPasteInProgress = false;

      if (this.processValueChangedList.length > 0) {
        this.inProgressLabel = this.$t(`update_progress`)
        this.processValueChanged(params.api);  
      }
    }
    , processCellForClipboard(params) {
      const rowData = params.node.data;
      let srcColId = params.column.colId;
      if (srcColId == this.mainColumnId) {
        srcColId = 'code';
      }
      return rowData[srcColId]
    }
    , detailLinkId(params) {
      return { num: params.node.data.num, code: params.node.data.code }
    }
    , openDetail({ num, code }) {
      this.editorNum = num
      this.editorCode = code
      this.editorShow = true
      this.resetAlert()
    }
    , currencySelectorOk({ selectedList=[] }={}) {
      this.processChange(selectedList)
    }
    , reloadData(callback=null) {
      enumService.list().then(response => {
        if (response != null && response.data != null) {
          const data = response.data
          if (data.jobCase != null && data[data.jobCase] != null) {
            const propertyList = data[data.jobCase]
            const keys = Object.keys(propertyList);
            for (const k of keys) {
              const obj = propertyList[k]
              const codes = Object.keys(obj)
              const list = []
              const disabledList = []
              for (const c of codes) {
                if (obj[c] < 0) {
                  disabledList.push({ code: c, num: obj[c] })
                } else {
                  list.push({ code: c, num: obj[c] })
                }
              }

              if (this.enumList[k] == null) {
                this.$set(this.enumList, k, { list, disabledList, redacted: [] })
              } else {
                this.$set(this.enumList[k], 'list', list)
                this.$set(this.enumList[k], 'disabledList', disabledList)
                this.$set(this.enumList[k], 'redacted', [])
              }
            }
          }

          //Fill up currency name property
          if (this.enumList.CurrencyEnum != null && Array.isArray(this.enumList.CurrencyEnum.list)) {
            const currencyList = this.enumList.CurrencyEnum.list
            for (const item of currencyList)   {
              const found = currencies.find(i => i.code === item.code)
              if (found != null) {
                item.name = found.name
              }
            }
          }

          if (Array.isArray(data.redacted) && data.redacted.length > 0) {
            const redactedList = data.redacted
            for (const r of redactedList) {
              if (r.length < 2) {
                continue
              }
              const tokens = r.split('.')
              const enumName = tokens[0]
              const redactedValue = tokens[1]
              if (this.enumList[enumName] == null) {
                this.enumList[enumName] = { redacted: [redactedValue], list: [], disabledList: [] }
              } else {
                if (Array.isArray(this.enumList[enumName].redacted)) {
                  this.enumList[enumName].redacted.push(redactedValue)
                } else {
                  this.enumList[enumName].redacted = [redactedValue]
                }

                if (Array.isArray(this.enumList[enumName].list) && this.enumList[enumName].list.length > 0) {
                  const found = this.enumList[enumName].list.find(i => i.code === redactedValue)
                  if (found != null) {
                    found.redacted = true
                  }
                }
              }
            }
          }

          if (this.activeTabName != null) {
            this.activeTabValue = this.enumList[this.activeTabName]
            if (this.gridApi != null) {
              this.gridApi.setGridOption('rowData', objectClone(this.activeTabValue.list))
              if (this.previousVScrollPosition != null && this.gridApi.gridBodyCtrl && this.gridApi.gridBodyCtrl.bodyScrollFeature) {
                this.gridApi.gridBodyCtrl.bodyScrollFeature.setVerticalScrollPosition(this.previousVScrollPosition)
              }
            }
            setTimeout(() => {
              this.gridApi.refreshCells({ force: true })
            })
          }

          if (typeof callback == 'function') {
            callback()
          }
        }
      }).catch(e => {
        console.error(e); //eslint-disable-line no-console
        this.isMissingData = true;
      })
    }
    , isEditable(params) {
      return params != null && params.data != null && params.data.redacted != true
    }
    , showSandboxInfo() {
      if (this.$store.state.sandbox.value) {
        this.resetAlert({ msg: this.$t('sandbox.enum_live_changes'), alertState: alertStateEnum.INFO })
        this.keepAlertFeedback = true; 
      }
    }
    , enforcePinnedColumnOrders(api) {
      if (api == null || api.isDestroyed()) {
        return;
      }
      const columnState = api.getColumnState();
          
      let needUpdate = false;
      const rowSelectorIndex = columnState.findIndex(i => i.colId == 'rowSelector');
      if (rowSelectorIndex > -1) {
        if (rowSelectorIndex != 0) {
          columnState.splice(0, 0, columnState.splice(rowSelectorIndex, 1)[0]);
          needUpdate = true;
        }
      }

      const mainColIndex = columnState.findIndex(i => i.colId == 'name');
      if (mainColIndex > -1) {
        let expectedPosition = 1;
        if (rowSelectorIndex == -1) {
          expectedPosition--;
        }
        if (mainColIndex != expectedPosition) {
          columnState.splice(expectedPosition, 0, columnState.splice(mainColIndex, 1)[0]);
          needUpdate = true;
        }
      }
      
      if (needUpdate) {
        api.applyColumnState({
          state: columnState,
          applyOrder: true
        })
      }
    }
  }
}
</script>

<style lang="scss">
</style>